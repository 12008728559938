<template>
  <a-modal
    :visible="value"
    :title="$t('upload_configs')"
    :footer="null"
    @cancel="$emit('input', false)"
  >
    <a-spin :spinning="importConfigLoading">
      <div class="flex flex-col">
        <ConfigField :title="$t('import_variants')">
          <a-radio-group
            v-model="importType"
            class="flex flex-col"
          >
            <a-radio
              v-for="(type, typeIndex) in importTypes"
              :key="typeIndex"
              class="mt-2"
              :value="type.value"
            >
              {{ type.label }}
            </a-radio>
          </a-radio-group>
        </ConfigField>

        <media-uploader
          v-if="importType"
          class="mt-2"
          :disabled="importConfigLoading"
          @fileLoaded="handleFileUpload"
        />
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import Api from "../../includes/logic/Api";
import { InputSetups } from "@/mixins/input-setups";
import { errorNotification, successNotification } from "@/includes/NotificationService";

import MediaUploader from "piramis-base-components/src/components/MediaUploader/MediaUploader.vue";
import { UseFields } from "piramis-base-components/src/components/Pi";
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";

import { snakeCase } from "lodash";

const ImportType = {
  'All': 'All',
  'Config': 'Config',
  'Triggers': 'Triggers',
}

export default {
  i18n: {
    messages: {
      ru: {
        import_variants: 'Тип импорта',
        import_config_all: 'Настройки и триггеры',
        import_config_config: 'Только настройки',
        import_config_triggers: 'Только триггеры',
      },
      en: {
        import_variants: 'Import type',
        import_config_all: 'Config and triggers',
        import_config_config: 'Only config',
        import_config_triggers: 'Only triggers',
      },
    }
  },

  name: "ImportConfigs",

  components: {
    ConfigField,
    MediaUploader
  },

  mixins: [ UseFields, InputSetups ],

  props: {
    value: Boolean
  },

  data: () => ({
    ImportType,
    importType: null,
    someFile:null,
    importConfigLoading: false,
  }),
  computed: {
    uploadParams() {
      const params = { chat_id: `${ this.$store.state.chatState.chat.chat_id }` }

      switch (this.importType) {
        case ImportType.Config:
          params.dont_import_triggers = true;
          break;
        case ImportType.Triggers:
          params.dont_import_config = true
          break
      }

      return params
    },
    importTypes() {
      return Object.values(ImportType)
        .map((value) => ({
          label: this.$t(`import_config_${ snakeCase(value) }`),
          value
        }))
    },
  },
  methods: {
    doRequest(formData) {
      this.importConfigLoading = true
      Api.sendRequest('tg', 'importchatconfig', formData)
        .then(this.successImport)
        .then(_ => successNotification())
        .catch(errorNotification)
        .finally(() =>{
          this.importConfigLoading = false
          this.someFile = null
          this.$emit('input',false)
        })
    },
    upload() {
      const fd = new FormData()

      Object.entries(this.uploadParams).forEach(([ k, v ]) => {
        fd.append(k, v)
      })

      fd.append('config', this.someFile)

      this.doRequest(fd)
    },
    handleFileUpload(file) {
      this.someFile = file.blobFile

      this.upload()
    },
    successImport() {
      this.$store.dispatch("requestChatConfig", {
        id: this.$route.params[EntityTypes.CHAT_ID],
        vm: this,
      })
    },
    errorUpload(e) {
      const message = JSON.parse(e.currentTarget.response).message;
      errorNotification(message)
    },
  }
}
</script>

<style scoped>

</style>
